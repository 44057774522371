<template>
    <b-col cols="5" md="3" :class="{'role-tile': true, 'selected-role': selected}" @click="$emit('roleSelected', role)">
        <h4 class="role-title">{{role.name}}</h4>
    </b-col>
</template>
<script>
	import DemonstrationsApi from "@/assets/js/DemonstrationsApi";
    export default {
        name: "RoleListTile",
        props: ['role', 'selected'],
        methods: {
			getIconImageUrl(){
				return DemonstrationsApi.getIconImageUrl(this.role.complexId);
			}
        },
    }
</script>