<template>
    <div class="roles-list">
        <radar-spinner v-if="loading" :animation-duration="2000" :size="60" color="#343a40" />
        <template v-else>
            <b-container v-if="terminalId != null && terminalId != ''" class="roles-list-tiles-wrapper">
                <b-alert v-if="sent" variant="success" show>Group sent to terminal, you'll be redirected to home in {{timeToRedirect}}s ...</b-alert>
                <b-alert v-if="error" variant="danger" show>{{errorMessage}}</b-alert>
                <b-alert v-else-if="roles.length == 0" variant="info" show>{{errorMessage}}</b-alert>
                <b-row v-if="roles.length > 0 && !error" class="roles-tiles">
                    <RoleListTile v-for="(role, id) in roles" :key="id" :selected="selectedRole !=null && selectedRole.id == role.id" :role="role" @roleSelected="selectedRole = $event"/>
                </b-row>
                <b-button pill :class="{'send-role-button': true, 'active': selectedRole !== null }" @click="sendRole()"><b-icon icon="cloud-upload" aria-hidden="true"></b-icon></b-button>
            </b-container>
            <b-alert v-else variant="danger" show>Please scan a valid QR-Code</b-alert>
        </template>
    </div>
</template>
<script>
    import GroupsApi from "@/assets/js/GroupsApi";
    import RoleListTile from "@/components/RoleListTile";
    import { RadarSpinner  } from 'epic-spinners'

    export default {
        name: "RolesListTiles",
        components: {RoleListTile, RadarSpinner},
        data() {
            return {
                terminalId: null,
                roles: [],
                loading: true,
                error: false,
                errorMessage: null,
                selectedRole: null,
                authenticationDone: localStorage.getItem("authenticationDone") === "true",
                timeToRedirect: 5,
                sent: false
            }
        },
        created() {
            if(this.authenticationDone){
                this.terminalId = this.$route.query.tId
                this.retrieveRoles()
            }else{
                this.$router.push({name: "pinauth", query: this.$route.query})
            }
            
        },
        methods: {
            retrieveRoles() {
				GroupsApi.getAll()
					.then(response => {
                        this.roles = response.data;
                        if(this.roles.length == 0){
                            this.errorMessage = "No role found"
                        }
                        this.loading = false;
                        
					})
					.catch(e => {
                        this.errorMessage = "Problem while retrieving roles from server please retry later"
                        this.error = true
						console.error(e);
					});
            },
            sendRole(){
                if(this.selectedRole != null){
                    console.log(this.selectedRole)
                    this.$mqtt.initMqttClient(() => {
                        let message = {
                            groupComplexId: this.selectedRole.complexId
                        }
                        let topic = this.$mqtt.topics.LOAD_GROUP_TOPIC.format({terminalId: this.terminalId})
                        console.log(topic)
                        this.$mqtt.publishMqtt(topic, JSON.stringify(message), () => {
                            this.sent = true
                            let timer = setInterval(() => {
                                if(this.timeToRedirect <= 0){
                                    clearInterval(timer)
                                    this.$router.push("/")
                                }else{
                                    this.timeToRedirect -= 1
                                }
                            }, 1000)
                        })
                    });
                    
                }
            }
        }
    }
</script>